<template>
	<div id="bibliothecha">
		<div class="collection-text" id="collection" ref="collection">
			<div class="title">
				<h2>Bibliotheca Graeca</h2>
			</div>
			<div class="description">
				<p>Η Συλλογή περιέχει όλες σχεδόν τις σημαντικές εκδόσεις, όλων των έργων που έχουν γραφτεί στα ελληνικά, ξεκινώντας από τον Όμηρο και τις απαρχές της ελληνικής γραμματείας, μέχρι την πτώση της Κωνσταντινούπολης το 1453. Τα θέματα που περιλαμβάνει αφορούν τη λογοτεχνία, φιλοσοφία και ιστορία, αλλά και τις φυσικές επιστήμες, τα μαθηματικά, την ιατρική, εκκλησιαστικά κείμενα και πολλά άλλα.</p>
				<p>
				Η συλλογή είναι γνωστή ως Bibliotheca Graeca, ως εκ του τίτλου του ομώνυμου έργου του μεγάλου Γερμανού λόγιου και βιβλιογράφου του 18ου αιώνα Φαβρικίου (Johann Albert Fabricius, 1668-1736), ο οποίος χρησιμοποίησε τον όρο για να περιγράψει το σύνολο της ελληνικής γραμματείας, όπως διαφυλάχθηκε μέσω των χειρογράφων, των παπύρων και των επιγραφών.</p>
			</div>
			<div class="links">
				<md-button class="more-btn">Ο ΣΥΛΛΕΚΤΗΣ Α. ΟΙΚΟΝΟΜΟΠΟΥΛΟΣ</md-button>
				<md-button class="more-btn">ΙΔΡΥΜΑ ΑΙΚΑΤΕΡΙΝΗΣ ΛΑΣΚΑΡΙΔΗ</md-button>
				<md-button @click="goToContact()" class="more-btn">ΕΠΙΚΟΙΝΩΝΙΑ</md-button>
			</div>
		</div>
			<div class="tree-container">
				<div class="inner"></div>
			</div>
		<div class="search-container centering">
			<div class="books">
				<div class="center">

					<img src="@/assets/img/photo-2.jpg" alt="">
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Collection",
	components: {
	},
	methods: {
		goToContact() {
			this.$router.push('contact')
		}
	}
}
</script>

<style scoped lang="scss">

#bibliothecha {
	background-image: url('../assets/img/bg_2.jpg') ;
}

	.collection-text {
		text-align: left;
		padding: 2.5% 16%;
	}

	h2 {
		font-size: calc(14px + (50 - 14) * ((100vw - 300px) / (1600 - 300)));
		margin: 6% 0 4% 0;
	}

	p {
		width: 70%;
		font-size: calc(14px + (18 - 14) * ((100vw - 300px) / (1600 - 300)));
		line-height: 1.6;
	}

	.more-btn {
		margin-left: -1px;
		font-size: calc(10px + (17 - 10) * ((100vw - 300px) / (1600 - 300)));
		width: 14%;
		color: rgba(0, 0, 0, 0.85);
		border: 1px solid $grey
	}

	.search-container {
		background: linear-gradient(0deg,$blue 55%, transparent 45%);
		padding-bottom: 3%;
		z-index: 100;
		width: 100%;
	}

	.books {
		display: flex;
		justify-content: space-evenly;
		padding: 0% 22%;
		align-items: flex-end;

		> div {
			flex:1 1 auto;
		}

		.left {
			margin-right: 5%;
		}

		.right {
			margin-right: 5%;
		}
	}

	.links {
		text-align: center;
		margin: 2rem 0;
		display: flex;
		width: 100%;

		.more-btn  {
			font-size: calc(10px + (15 - 10) * ((100vw - 300px) / (1600 - 300)));
			max-width: 290px;
			flex: 1 auto;
			background: #fff;
			border-color:#979797c3;
			height: 2.5rem;
		}
	}

	.tree-container {
		position: absolute;
		right: 0;
		background-image: url('../assets/img/bg_3.png');
		background-size: cover;
		background-repeat: no-repeat;
    height: 51vw;
    width: 140px;
    padding: 10%;
    -webkit-transform: translateY(-43%);
    transform: translateY(-64%);
		z-index: 0;
	}

	.search {
		padding: 1% 2% 2% 2%;
		width: 65%;
		margin: 2% auto 0 auto;
		background-color: $dark-blue;
		overflow: auto;

		h2 {
			color:#FFF;
			font-size: calc(14px + (35 - 14) * ((100vw - 300px) / (1600 - 300)));
			text-align: left;
		}
	}

@media screen and (max-width: 1024px) {

	.links {
		flex-direction: column;
		align-items: center;

		.more-btn {
			width: 45%;
		}
	}
}

@media screen and (max-width: 980px) {
	.description p {
		width: 80%;
	}

	.links {
		.more-btn {
			width: 65%;
		}
	}

	.tree-container {
		display: none;
	}
}

	@media(max-width: 560px) {
		.search {
			width: 90%;
			padding: 5%;
		}
	}

</style>
