import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import Banner from "@/components/ui/Banner.vue"
import router from './router'
import store from './store'
import axios from 'axios'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'
import VueMaterial from 'vue-material'
import Loader from "@/components/ui/Loader.vue"
import VueScrollTo from 'vue-scrollto'
import VTooltip from 'v-tooltip'

Vue.config.productionTip = false

Vue.prototype.$http = axios

Vue.use(VueScrollTo)
Vue.use(VTooltip)
Vue.use(VueMaterial)

Vue.use(VueScrollTo, {
	container: "body",
	duration: 500,
	easing: "ease",
	offset: 0,
	force: true,
	cancelable: true,
	onStart: false,
	onDone: false,
	onCancel: false,
	x: false,
	y: true
})

Vue.component('main-banner', Banner)
Vue.component('bib-loader', Loader)

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
