<template>
	<div id="app">
		<div id="nav">
			<app-header class="main-header"></app-header>
      <app-sidenav class="side-nav"></app-sidenav>
			<!-- <router-link to="/">
					<md-icon>home</md-icon>
			</router-link> |
			<router-link to="/about">About</router-link> -->
		</div>
		<router-view />
		<app-footer />
	</div>
</template>

<script>
import AppHeader from "@/components/Navigation/AppHeader"
import AppFooter from "@/components/AppFooter"
import AppSidenav from "@/components/Navigation/AppSidenav"

export default {
	components: {
		AppHeader,
		AppSidenav,
		AppFooter
	}
}
</script>

<style lang="scss">
#app {
	font-family: 'Avenir', Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #000;
	background-color: #fff;
}

#nav {
	background-color: #fff;
}

@media (min-width: 997px) {

	#nav {
		height: 7vw;
		display: flex;
		align-items: center;
	}

	.side-nav {
		display: none!important;
	}
}

@media(max-width: 996px) {
	.main-header {
		display: none!important;
	}
}

</style>
