import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'home',
		component: Home
	},
	{
		path: '/',
		name: 'collection',
		component: Home
	},
	{
		path: '/about',
		name: 'about',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
	},
	{
		path: '/search',
		name: 'search',
		component: () => import(/* webpackChunkName: "about" */ '../views/SearchResults.vue')
	},
	{
		path: '/book-profile/:bookId',
		name: 'book-profile',
		component: () => import(/* webpackChunkName: "about" */ '../views/SingleBook.vue')
	},
	{
		path: '/thesaurus/:thesId',
		name: 'thesaurus',
		component: () => import(/* webpackChunkName: "about" */ '../views/Thesaurus.vue')
	},
	{
		path: '/terms-of-use',
		name: 'terms',
		component: () => import(/* webpackChunkName: "about" */ '../views/Terms.vue')
	},
	{
		path: '/contact',
		name: 'contact',
		component: () => import(/* webpackChunkName: "about" */ '../views/ContactPage.vue')
	},
	{
		path: '*', redirect: '/'
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		}
		if (to.hash) {
			return { selector: to.hash }
		}
		return { x: 0, y: 0 }
	},
	routes
})

export default router
